import React from "react";

function Shop() {
  return (
    <div className="text-center mo-4">
      <h2 className="h2">Shop Page</h2>
    </div>
  );
}

export default Shop;
