export const products = [
  {
    id: 1,
    title: "Single-breasted Trenchcoat",
    price: 87.9,
    newPrice: null,
    description:
      "Your perfect pack for everyday use and walks in the forest. Stash yourin the padded sleeve, your everyday",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/1.jpg",
    rating: {
      rate: 3.9,
      count: 120,
    },
    size: ["xs", "s", "m", "l", "xl", "xxl"],
    color: ["tan", "green", "yellow"],
    inStock: 5,
  },
  {
    id: 2,
    title: "Slim Taper Fit Jeans",
    price: 58.99,
    newPrice: null,
    description:
      "use and walks in the forest. Stash your in the padded sleeve, your everyday",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/2.jpg",
    rating: {
      rate: 3.9,
      count: 100,
    },
    size: ["m", "l", "xxl"],
    color: ["gray", "blue", "black"],
    inStock: 5,
  },
  {
    id: 3,
    title: "Cotton Polo Regular Fit",
    price: 58.99,
    newPrice: null,
    description:
      "Cotton Polo Regular. Stash your in the padded sleeve, your everyday",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/3.jpg",
    rating: {
      rate: 3.5,
      count: 110,
    },
    size: ["m", "l", "xl"],
    color: ["teal", "brown", "green"],
    inStock: 12,
  },
  {
    id: 4,
    title: "3 Color Collection of T-shirts",
    price: 55.9,
    newPrice: 51.99,
    description:
      "3 Color Collection of T-shirts Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/4.jpg",
    rating: {
      rate: 4.6,
      count: 259,
    },
    size: ["s", "m", "l"],
    color: [],
    inStock: 3,
  },
  {
    id: 5,
    title: "Mens Cotton Jacket",
    price: 55.99,
    newPrice: 50.2,
    description:
      "great outerwear jackets for Spring/Autumn/Winter, suitable for many occasions, such as working, hiking, camping, mountain/rock climbing, cycling, traveling or other outdoors. Good gift choice for you or your family member. A warm hearted love to Father, husband or son in this thanksgiving or Christmas Day.",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/5.jpg",
    rating: {
      rate: 4.7,
      count: 500,
    },
    size: ["m", "l", "xxl"],
    color: ["green", "yellow"],
    inStock: 3,
  },
  {
    id: 6,
    title: "Mens Casual Slim Fit",
    price: 15.99,
    newPrice: null,
    description:
      "The color could be slightly different between on the screen and in practice. / Please note that body builds vary by person, therefore, detailed size information should be reviewed below on the product description.",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/6.jpg",
    rating: {
      rate: 2.1,
      count: 430,
    },
    size: ["s", "m", "l"],
    color: ["blue", "gray", "orange"],
    inStock: 7,
  },
  {
    id: 7,
    title: "Sports Hooded Sweatshirt",
    price: 28.99,
    newPrice: 25.99,
    description:
      "Sports Hooded Sweatshirt High quality materials The color could be slightly different between on the screen and in practice. / Please note that body builds vary by person, therefore, detailed size information should be reviewed below on the product description.",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/7.jpg",
    rating: {
      rate: 3.1,
      count: 60,
    },
    size: ["m", "l"],
    color: ["red", "blue", "white", "orange"],
    inStock: 5,
  },
  {
    id: 8,
    title: "Printed Hooded Top",
    price: 22.99,
    newPrice: null,
    description:
      "Hooded Sweatshirt High quality materials The color could be slightly different between on the screen and in practice. / Please note that body builds vary by person, therefore, detailed size information should be reviewed below on the product description.",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/8.jpg",
    rating: {
      rate: 4.7,
      count: 20,
    },
    size: ["m", "l", "xl"],
    color: ["white", "purple"],
    inStock: 3,
  },
  {
    id: 9,
    title: "Palm Print Jacket",
    price: 129.99,
    newPrice: 122.99,
    description:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.could be slightly different between on the screen and in practice. / Please note that body builds vary by person, therefore, detailed size information should be reviewed below on the product description.",
    category: "men's clothing",
    image: "https://api.atef-gad.com/images/app1/products/9.jpg",
    rating: {
      rate: 3.2,
      count: 55,
    },
    size: ["xl", "xxl"],
    color: ["black", "gray"],
    inStock: 2,
  },
  {
    id: 15,
    title: "Polarized Sunglasses",
    price: 95,
    newPrice: null,
    description:
      "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/15.jpg",
    rating: {
      rate: 4.6,
      count: 400,
    },
    size: [],
    color: ["black", "blue"],
    inStock: 5,
  },
  {
    id: 16,
    title: "5-Pack Multicolor Bracelets",
    price: 12.6,
    newPrice: 9.99,
    description:
      "Satisfaction Guaranteed. Return or exchange any order within 30 days.Designed and sold by Hafeez Center in the United States. Satisfaction Guaranteed. Return or exchange any order within 30 days.",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/16.jpg",
    rating: {
      rate: 3.9,
      count: 70,
    },
    size: [],
    color: ["brown", "blue"],
    inStock: 5,
  },
  {
    id: 17,
    title: "Solid Gold Petite Micropave ",
    price: 168,
    newPrice: 162.2,
    description:
      "Satisfaction Guaranteed. Return or exchange any order within 30 days.Designed and sold by Hafeez Center in the United States. Satisfaction Guaranteed. Return or exchange any order within 30 days.",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/17.jpg",
    rating: {
      rate: 3.9,
      count: 70,
    },
    size: ["s", "m"],
    color: ["gold", "gray"],
    inStock: 2,
  },
  {
    id: 18,
    title: "Polarized Sunglasses for kidz",
    price: 15.99,
    newPrice: null,
    description:
      "Polarized Sunglasses. Made of 316L Stainless Steel Polarized Sunglasses. Made of 316L Stainless Steel Polarized Sunglasses. Made of 316L Stainless Steel Polarized Sunglasses. Made of 316L Stainless Steel",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/18.jpg",
    rating: {
      rate: 4.5,
      count: 220,
    },
    size: [],
    color: ["gray"],
    inStock: 1,
  },
  {
    id: 19,
    title: "Pierced Owl Rose Gold Plated Stainless Steel Double",
    price: 10.99,
    newPrice: 8.25,
    description:
      "Rose Gold Plated Double Flared Tunnel Plug Earrings. Made of 316L Stainless Steel",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/19.jpg",
    rating: {
      rate: 1.9,
      count: 100,
    },
    size: ["s", "m"],
    color: ["gold"],
    inStock: 5,
  },
  {
    id: 20,
    title: "TH Jeans City Backpack",
    price: 79.99,
    newPrice: 69.99,
    description: "TH Jeans City Backpack. Made of 316L Stainless Steel",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/20.jpg",
    rating: {
      rate: 2.9,
      count: 20,
    },
    size: [],
    color: ["black", "gold"],
    inStock: 0,
  },
  {
    id: 21,
    title: "Polarized Sunglasses",
    price: 79.99,
    newPrice: 69.99,
    description:
      "Polarized Sunglasses. Made of 316L Stainless Steel Polarized Sunglasses. Made of 316L Stainless Steel Polarized Sunglasses. Made of 316L Stainless Steel Polarized Sunglasses. Made of 316L Stainless Steel",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/21.jpg",
    rating: {
      rate: 2.9,
      count: 20,
    },
    size: [],
    color: ["gold", "black", "blue"],
    inStock: 5,
  },
  {
    id: 22,
    title: "Travel handle totte bags",
    price: 109.99,
    newPrice: 100.99,
    description:
      "Travel handle totte bags. Made of 316L Stainless Steel Travel handle totte bags. Made of 316L Stainless Steel Travel handle totte bags. Made of 316L Stainless Steel Travel handle totte bags. Made of 316L Stainless Steel",
    category: "accessories",
    image: "https://api.atef-gad.com/images/app1/products/22.jpg",
    rating: {
      rate: 1.9,
      count: 10,
    },
    size: [],
    color: ["gray", "gold", "black"],
    inStock: 4,
  },
  {
    id: 30,
    title: "Smart Watch Series 5",
    price: 94.99,
    newPrice: 89.99,
    description:
      "Smart Watch Series 5 transfers Improve PC Performance High Capacity; Compatibility Formatted NTFS for Windows 10, Windows 8.1, Windows 7; Reformatting may be required for other operating systems; Compatibility may vary depending on user’s hardware configuration and operating system",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/30.jpg",
    rating: {
      rate: 3.3,
      count: 203,
    },
    size: [],
    color: ["white", "black", "gray"],
    inStock: 8,
  },
  {
    id: 31,
    title: "Speaker with Voice Control",
    price: 169,
    newPrice: null,
    description:
      "Speaker with Voice Control Easy upgrade for faster boot up, shutdown, application load and response (As compared to 5400 RPM SATA 2.5” hard drive; Based on published specifications and internal benchmarking tests using PCMark vantage scores) Boosts burst write performance, making it ideal for typical PC workloads The perfect balance of performance and reliability Read/write speeds of up to 535MB/s/450MB/s (Based on internal testing; Performance may vary depending upon drive capacity, host device, OS and application.)",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/31.jpg",
    rating: {
      rate: 2.9,
      count: 470,
    },
    size: ["64", "128"],
    color: ["black", "gray", "red"],
    inStock: 5,
  },
  {
    id: 32,
    title: "Silicon Power 256GB SSD 3D NAND A55",
    price: 109,
    newPrice: 100.5,
    description:
      "3D NAND flash are applied to deliver high transfer speeds Remarkable transfer speeds that enable faster bootup and improved overall system performance. The advanced SLC Cache Technology allows performance boost and longer lifespan 7mm slim design suitable for Ultrabooks and Ultra-slim notebooks. Supports TRIM command, Garbage Collection technology, RAID, and ECC (Error Checking & Correction) to provide the optimized performance and enhanced reliability.",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/32.jpg",
    rating: {
      rate: 4.8,
      count: 319,
    },
    size: ["32", "64", "128"],
    color: [],
    inStock: 4,
  },
  {
    id: 33,
    title:
      "WD 4TB Gaming Drive Works with Playstation 4 Portable External Hard Drive",
    price: 114,
    newPrice: null,
    description:
      "Expand your PS4 gaming experience, Play anywhere Fast and easy, setup Sleek design with high capacity, 3-year manufacturer's limited warranty",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/33.jpg",
    rating: {
      rate: 4.8,
      count: 400,
    },
    size: [],
    color: ["black", "gray", "blue"],
    inStock: 2,
  },
  {
    id: 34,
    title: "Acer SB220Q bi 21.5 inches Full HD (1920 x 1080) IPS Ultra-Thin",
    price: 599,
    newPrice: 450.99,
    description:
      "21. 5 inches Full HD (1920 x 1080) widescreen IPS display And Radeon free Sync technology. No compatibility for VESA Mount Refresh Rate: 75Hz - Using HDMI port Zero-frame design | ultra-thin | 4ms response time | IPS panel Aspect ratio - 16: 9. Color Supported - 16. 7 million colors. Brightness - 250 nit Tilt angle -5 degree to 15 degree. Horizontal viewing angle-178 degree. Vertical viewing angle-178 degree 75 hertz",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/34.jpg",
    rating: {
      rate: 2.9,
      count: 250,
    },
    size: ["21", "27", "32"],
    color: ["black", "gray"],
    inStock: 8,
  },
  {
    id: 35,
    title:
      "Samsung 49-Inch CHG90 144Hz Curved Gaming Monitor (LC49HG90DMNXZA) – Super Ultrawide Screen QLED ",
    price: 999.99,
    newPrice: 860.9,
    description:
      "49 INCH SUPER ULTRAWIDE 32:9 CURVED GAMING MONITOR with dual 27 inch screen side by side QUANTUM DOT (QLED) TECHNOLOGY, HDR support and factory calibration provides stunningly realistic and accurate color and contrast 144HZ HIGH REFRESH RATE and 1ms ultra fast response time work to eliminate motion blur, ghosting, and reduce input lag",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/35.jpg",
    rating: {
      rate: 2.2,
      count: 140,
    },
    size: ["27", "32", "49", "55"],
    color: ["black", "gray"],
    inStock: 8,
  },
  {
    id: 37,
    title: "360 Degrees Camera",
    price: 97.99,
    newPrice: null,
    description:
      "360 Degrees Camera Wireless with dual 5.5 inch screen side by side QUANTUM DOT (QLED) TECHNOLOGY, HDR support and factory calibration provides stunningly realistic and accurate color and contrast 144HZ HIGH REFRESH RATE and 1ms ultra fast response time work to eliminate motion blur, ghosting, and reduce input lag",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/37.jpg",
    rating: {
      rate: 3.2,
      count: 40,
    },
    size: [],
    color: ["black", "blue"],
    inStock: 4,
  },
  {
    id: 36,
    title: "Wireless Headphones",
    price: 156.99,
    newPrice: null,
    description:
      "Wireless Headphones with dual 27 inch screen side by side QUANTUM DOT (QLED) TECHNOLOGY, HDR support and factory calibration provides stunningly realistic and accurate color and contrast 144HZ HIGH REFRESH RATE and 1ms ultra fast response time work to eliminate motion blur, ghosting, and reduce input lag",
    category: "electronics",
    image: "https://api.atef-gad.com/images/app1/products/36.jpg",
    rating: {
      rate: 4.2,
      count: 22,
    },
    size: [],
    color: ["black", "blue"],
    inStock: 4,
  },
  {
    id: 50,
    title: "Women's 2-in-1 Snowboard Jacket Winter Coats",
    price: 56.99,
    newPrice: null,
    description:
      "Note:The Jackets is US standard size, Please choose size as your usual wear Material: 100% Polyester; Detachable Liner Fabric: Warm Fleece. Detachable Functional Liner: Skin Friendly, Lightweigt and Warm.Stand Collar Liner jacket, keep you warm in cold weather. Zippered Pockets: 2 Zippered Hand Pockets, 2 Zippered Pockets on Chest (enough to keep cards or keys)and 1 Hidden Pocket Inside.Zippered Hand Pockets and Hidden Pocket keep your things secure. Humanized Design: Adjustable and Detachable Hood and Adjustable cuff to prevent the wind and water,for a comfortable fit. 3 in 1 Detachable Design provide more convenience, you can separate the coat and inner as needed, or wear it together. It is suitable for different season and help you adapt to different climates",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/50.jpg",
    rating: {
      rate: 2.6,
      count: 235,
    },
    size: ["l", "xl"],
    color: ["red", "blue", "purple"],
    inStock: 3,
  },
  {
    id: 51,
    title:
      "Lock and Love Women's Removable Hooded Faux Leather Moto Biker Jacket",
    price: 29.95,
    newPrice: null,
    description:
      "100% POLYURETHANE(shell) 100% POLYESTER(lining) 75% POLYESTER 25% COTTON (SWEATER), Faux leather material for style and comfort / 2 pockets of front, 2-For-One Hooded denim style faux leather jacket, Button detail on waist / Detail stitching at sides, HAND WASH ONLY / DO NOT BLEACH / LINE DRY / DO NOT IRON",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/51.jpg",
    rating: {
      rate: 2.9,
      count: 340,
    },
    size: ["m", "l"],
    color: ["black"],
    inStock: 1,
  },
  {
    id: 52,
    title: "Rain Jacket Women Windbreaker Striped Climbing Raincoats",
    price: 39.99,
    newPrice: null,
    description:
      "Lightweight perfet for trip or casual wear---Long sleeve with hooded, adjustable drawstring waist design. Button and zipper front closure raincoat, fully stripes Lined and The Raincoat has 2 side pockets are a good size to hold all kinds of things, it covers the hips, and the hood is generous but doesn't overdo it.Attached Cotton Lined Hood with Adjustable Drawstrings give it a real styled look.",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/52.jpg",
    rating: {
      rate: 3.8,
      count: 679,
    },
    size: ["xs", "s", "m", "l", "xl", "xxl"],
    color: ["blue", "gray"],
    inStock: 3,
  },
  {
    id: 53,
    title: "MBJ Women's Solid Short Sleeve Boat Neck V ",
    price: 9.85,
    newPrice: null,
    description:
      "95% RAYON 5% SPANDEX, Made in USA or Imported, Do Not Bleach, Lightweight fabric with great stretch for comfort, Ribbed on sleeves and neckline / Double stitching on bottom hem",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/53.jpg",
    rating: {
      rate: 4.7,
      count: 130,
    },
    size: ["l", "xl"],
    color: ["white", "red", "blue"],
    inStock: 3,
  },
  {
    id: 19,
    title: "Opna Women's Short Sleeve Moisture",
    price: 7.95,
    newPrice: 6.92,
    description:
      "100% Polyester, Machine wash, 100% cationic polyester interlock, Machine Wash & Pre Shrunk for a Great Fit, Lightweight, roomy and highly breathable with moisture wicking fabric which helps to keep moisture away, Soft Lightweight Fabric with comfortable V-neck collar and a slimmer fit, delivers a sleek, more feminine silhouette and Added Comfort",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/54.jpg",
    rating: {
      rate: 4.5,
      count: 146,
    },
    size: ["m", "l"],
    color: ["red", "white"],
    inStock: 3,
  },
  {
    id: 55,
    title: "Cotton Lace Blouse",
    price: 30.99,
    newPrice: 28.5,
    description:
      "95% Cotton,5% Spandex, Features: Casual, Short Sleeve, Letter Print,V-Neck,Fashion Tees, The fabric is soft and has some stretch., Occasion: Casual/Office/Beach/School/Home/Street. Season: Spring,Summer,Autumn,Winter.",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/55.jpg",
    rating: {
      rate: 3.6,
      count: 145,
    },
    color: ["white"],
    inStock: 1,
  },
  {
    id: 56,
    title: "Mom High Waist Shorts",
    price: 39.99,
    newPrice: null,
    description:
      "5% Spandex, Features: Casual, Short Sleeve, Letter Print,V-Neck,Fashion Tees, The fabric is soft and has some stretch., Occasion: Casual/Office/Beach/School/Home/Street. Season: Spring,Summer,Autumn,Winter.",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/56.jpg",
    rating: {
      rate: 4.2,
      count: 225,
    },
    color: ["blue", "gray"],
    inStock: 3,
  },
  {
    id: 57,
    title: "Women Sports Jacket",
    price: 39.99,
    newPrice: null,
    description:
      "Lightweight perfet for trip or casual wear---Long sleeve with hooded, adjustable drawstring waist design. Button and zipper front closure raincoat, fully stripes Lined and The Raincoat has 2 side pockets are a good size to hold all kinds of things, it covers the hips, and the hood is generous but doesn't overdo it.Attached Cotton Lined Hood with Adjustable Drawstrings give it a real styled look.",
    category: "women's clothing",
    image: "https://api.atef-gad.com/images/app1/products/57.jpg",
    rating: {
      rate: 3.9,
      count: 105,
    },
    color: ["Pink", "green"],
    inStock: 2,
  },
];

export const users = [
  {
    address: {
      geolocation: {
        lat: "-37.3159",
        long: "81.1496",
      },
      city: "kilcoole",
      street: "new road",
      number: 7682,
      zipcode: "12926-3874",
    },
    id: 1,
    email: "john@gmail.com",
    username: "johnd",
    password: "m38rmF$",
    name: {
      firstname: "john",
      lastname: "doe",
    },
    phone: "1-570-236-7033",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "-37.3159",
        long: "81.1496",
      },
      city: "kilcoole",
      street: "Lovers Ln",
      number: 7267,
      zipcode: "12926-3874",
    },
    id: 2,
    email: "morrison@gmail.com",
    username: "mor_2314",
    password: "83r5^_",
    name: {
      firstname: "david",
      lastname: "morrison",
    },
    phone: "1-570-236-7033",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "40.3467",
        long: "-30.1310",
      },
      city: "Cullman",
      street: "Frances Ct",
      number: 86,
      zipcode: "29567-1452",
    },
    id: 3,
    email: "kevin@gmail.com",
    username: "kevinryan",
    password: "kev02937@",
    name: {
      firstname: "kevin",
      lastname: "ryan",
    },
    phone: "1-567-094-1345",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "50.3467",
        long: "-20.1310",
      },
      city: "San Antonio",
      street: "Hunters Creek Dr",
      number: 6454,
      zipcode: "98234-1734",
    },
    id: 4,
    email: "don@gmail.com",
    username: "donero",
    password: "ewedon",
    name: {
      firstname: "don",
      lastname: "romer",
    },
    phone: "1-765-789-6734",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "40.3467",
        long: "-40.1310",
      },
      city: "san Antonio",
      street: "adams St",
      number: 245,
      zipcode: "80796-1234",
    },
    id: 5,
    email: "derek@gmail.com",
    username: "derek",
    password: "jklg*_56",
    name: {
      firstname: "derek",
      lastname: "powell",
    },
    phone: "1-956-001-1945",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "20.1677",
        long: "-10.6789",
      },
      city: "el paso",
      street: "prospect st",
      number: 124,
      zipcode: "12346-0456",
    },
    id: 6,
    email: "david_r@gmail.com",
    username: "david_r",
    password: "3478*#54",
    name: {
      firstname: "david",
      lastname: "russell",
    },
    phone: "1-678-345-9856",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "10.3456",
        long: "20.6419",
      },
      city: "fresno",
      street: "saddle st",
      number: 1342,
      zipcode: "96378-0245",
    },
    id: 7,
    email: "miriam@gmail.com",
    username: "snyder",
    password: "f238&@*$",
    name: {
      firstname: "miriam",
      lastname: "snyder",
    },
    phone: "1-123-943-0563",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "50.3456",
        long: "10.6419",
      },
      city: "mesa",
      street: "vally view ln",
      number: 1342,
      zipcode: "96378-0245",
    },
    id: 8,
    email: "william@gmail.com",
    username: "hopkins",
    password: "William56$hj",
    name: {
      firstname: "william",
      lastname: "hopkins",
    },
    phone: "1-478-001-0890",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "40.12456",
        long: "20.5419",
      },
      city: "miami",
      street: "avondale ave",
      number: 345,
      zipcode: "96378-0245",
    },
    id: 9,
    email: "kate@gmail.com",
    username: "kate_h",
    password: "kfejk@*_",
    name: {
      firstname: "kate",
      lastname: "hale",
    },
    phone: "1-678-456-1934",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "30.24788",
        long: "-20.545419",
      },
      city: "fort wayne",
      street: "oak lawn ave",
      number: 526,
      zipcode: "10256-4532",
    },
    id: 10,
    email: "jimmie@gmail.com",
    username: "jimmie_k",
    password: "klein*#%*",
    name: {
      firstname: "jimmie",
      lastname: "klein",
    },
    phone: "1-104-001-4567",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "30.24788",
        long: "-20.545419",
      },
      city: "giza",
      street: "ahmed bn ali",
      number: 506,
      zipcode: "14532",
    },
    id: 11,
    email: "test@gmail.com",
    username: "test",
    password: "test123",
    name: {
      firstname: "demo",
      lastname: "user",
    },
    phone: "1-104-001-4567",
    __v: 0,
  },
  {
    address: {
      geolocation: {
        lat: "30.24788",
        long: "-20.545419",
      },
      city: "cairo",
      street: "abass",
      number: 506,
      zipcode: "14532",
    },
    id: 12,
    email: "atef@gmail.com",
    username: "atef",
    password: "atef123",
    name: {
      firstname: "atef",
      lastname: "gad",
    },
    phone: "1-104-001-4567",
    __v: 0,
  },
];
